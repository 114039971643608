<template>
    <div>
      <fieldset style="width:97%">
        <legend>搜索信息</legend>
        <el-row :gutter="20">
          <el-col :span="5">
            <el-date-picker
                v-model="form.date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                type="daterange"
                style="width: 100%"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-col>
          <el-col :span="4">
            <el-select v-model="form.shopId" placeholder="店铺" clearable>
              <el-option
                  v-for="item in shopIdArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select v-model="form.payeeId" placeholder="收款人" clearable>
              <el-option
                  v-for="item in payeeArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="search" size="medium">搜索</el-button>
          </el-col>
        </el-row>
      </fieldset>
      <el-row :gutter="20">
        <p class="title">{{ form.dateString }}全国 支出 分布图</p>
        <el-col :span="12">
          <div id="spendView"/>
        </el-col>
        <el-col :span="11" :offset="1">
          <el-table
              :data="spendData"
              show-summary
              row-key="projectName"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
              style="width: 80%">
            <el-table-column prop="projectName" label="项目名称"/>
            <el-table-column prop="sumMoney" label="金额"/>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </template>

<script>
import {Pie} from '@antv/g2plot';

export default {
  name: "spend-analysis",
  data() {
    return {
      form:{
        date:this.$dateUtils.getMonthStartEndDayStrArray(),
        dateString: this.$dateUtils.getMonthStartEndDayStr(),
        // shopId:"",
        // payeeId:"",
        tenantCrop:localStorage.getItem("tenantCrop"),
      },

      spendData:[],
      spendTableData:[],
      spendView:{},

      shopIdArray:[],
      payeeArray:[],

    }
  },
  created() {

    this.queryShopIdArray();
    this.queryPayeeIdArray();
  },
  mounted() {
    this.initSpendView();
    this.querySpend();
  },
  methods:{
    search(){
      this.form.dateString=this.$dateUtils.stringArrayToString(this.form.date)
      this.querySpend();
    },
    querySpend(){
      this.$axios({
        method: 'get',
        url: '/shopReports/querySpendAnalysis',
        params: {
          date:this.form.dateString,
          payeeId:this.form.payeeId,
          shopId:this.form.shopId,
          tenantCrop:this.form.tenantCrop
        },
      }).then(response => {
        this.spendData = response.data.data[0];
        this.spendView.changeData(this.spendData);
      });
    },
    //查询店铺
    queryShopIdArray() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopIdArray = JSON.parse(response.data.data)
      })
    },
    //查询收款人
    queryPayeeIdArray(){
      this.$selectUtils.queryEmpIds().then(response=>{
        this.payeeArray=JSON.parse(response.data.data);
      })
    },
    initSpendView() {
      this.spendView = new Pie('spendView', {
        appendPadding: 1,
        data: this.spendData,
        mate: {},
        angleField: 'sumMoney',
        colorField: 'projectName',
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name} {percentage}',
        },
        interactions: [{type: 'pie-legend-active'}, {type: 'element-active'}],
      })
      this.spendView.render();
    },

    childrenRol({row, column, rowIndex, columnIndex}) {
      return "color: #4c8daa;";
    },
  },
}
</script>

<style scoped>
.title {
  margin: 2% 2%;
  font-size: 30px;
}
/deep/ .el-table__row--level-1 {
  color: #409EFF;
}
</style>